import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { Headline, Image } from '~/components/atoms';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Align } from '~/components/atoms/headline/Headline';
import { CztImage } from '~/utils/atoms/image';
import { CztWidgets } from '~/utils/views/widgets';

import style from './ContentBlock.scss';
import { Link } from '~/components/molecules';
import { ContentBlockInterface, Position, ThemeStyleEnum } from './types';
import ImageSource from '~/components/templates/common/ImageSource';

const rootClass: string = 'czt-content-block';

@Component({
  style,
})
export default class ContentBlock extends VueComponent<ContentBlockInterface>
  implements ContentBlockInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ default: '', type: String })
  public caption!: string;

  @Prop({ required: true, type: String })
  public content!: string;

  @Prop()
  public image?: CztImage;

  @Prop()
  public imageSource?: string;

  @Prop()
  public imageUrl?: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: Position.LEFT })
  public position!: Position;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  @Prop({ default: ThemeStyleEnum.None })
  public themeStyle!: ThemeStyleEnum;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  public className = CztWidgets.HTML;

  protected get isDark() {
    return this.themeStyle === ThemeStyleEnum.Dark;
  }

  public render() {
    const rootClasses: string[] = [rootClass, 'czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-top');
    }

    if (this.isDark) {
      rootClasses.push(`${rootClass}--dark`);
    }

    return (
      <div id={this.anchorId} class={rootClasses.join(' ')}>
        <v-container>
          <v-row>
            <v-col cols={12} class={`${rootClass}__headline`}>
              <Headline
                level={this.side ? 4 : 2}
                underscore
                align={Align.LEFT}
                light={this.isDark}
              >
                {this.title}
              </Headline>
            </v-col>
            {this.position === Position.LEFT && this.renderSideContent()}
            <v-col class='pb-0 pt-md-0'>
              <HtmlRenderer
                content={this.content}
                side={this.side}
                light={this.isDark}
              />
            </v-col>
            {this.position === Position.RIGHT && this.renderSideContent()}
          </v-row>
        </v-container>
      </div>
    );
  }

  protected renderSideContent() {
    if (!this.image) {
      return;
    }
    const sizes = [
      `(min-width: 1264px) ${(1150 / 12) * 4}px`,
      `(min-width: 1008px) ${(1000 / 12) * 5}px`,
      `(min-width: 768px) ${(710 / 12) * 6}px`,
      `100vw`,
    ];
    const img = (
      <Image
        isFirst={this.isFirst}
        src={this.image.src}
        alt={this.image.alt}
        sizes={sizes.join(', ')}
      />
    );

    const component = this.imageUrl ? (
      <Link url={this.imageUrl}>{img}</Link>
    ) : (
      img
    );
    // If side content column width should ever be changed, sizes need to be adjusted too
    return (
      <v-col
        class={`${rootClass}__side pb-0 pt-md-0`}
        cols={12}
        md={6}
        lg={5}
        xl={4}
        tag='figure'
      >
        <div class={`${rootClass}__side__image`}>
          {component}
          {!!this.imageSource?.trim() && (
            <ImageSource imageSource={this.imageSource} />
          )}
        </div>
        {this.caption && (
          <figcaption
            class={{
              'czt-description': true,
              'czt-description--light': this.isDark,
            }}
          >
            {this.caption}
          </figcaption>
        )}
      </v-col>
    );
  }
}
